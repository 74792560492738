import { send } from "utils/api";
import { MarketingEvent, track } from "utils/telemetry";
import MarketingForm, { type FormSubmissionHandler } from "./form";

interface Props {
  title?: string;
  description?: string;
}

export default function StayInTouch({ title, description }: Props) {
  const onSubmit: FormSubmissionHandler = async (email) => {
    try {
      const res = await send("ms/add", { email });

      if (res.data?.success) {
        track(MarketingEvent.emailSubscribed);
        return { type: "success", message: "Thank you!" };
      }
    } catch (error) {
      console.error("email signup", error);
    }

    return {
      type: "error",
      message: "We could not add your email address, please try again.",
    };
  };

  return (
    <MarketingForm
      formId="stay-in-touch"
      title={title ?? "Stay in Touch"}
      titleColor="white"
      description={
        description ??
        "Sign up to receive discounts or information on big updates (we'll only send you important information)."
      }
      descriptionColor="white"
      descriptionMaxWidth="400px"
      background="linear-gradient(76.02deg, #133364 10.39%, #0A61B0 34.44%, rgba(0, 146, 255, 0.63) 62.06%, rgba(0, 194, 255, 0) 90.69%), #133364"
      inputType="email"
      inputPlaceholder="Enter your email"
      onSubmit={onSubmit}
    />
  );
}
