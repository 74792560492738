import { forwardRef, useState } from "react";
import s from "styled-components";

const convertToSlug = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

type InputProps = {
  placeholder: string;
  label: string;

  value: string;
  onChange: (val: string) => void;

  tag?: string;
  required?: boolean;
  type?: string;
  maxLength?: number;
  disabled?: boolean;

  higlighted?: boolean;
};

const AuthInput = forwardRef<HTMLInputElement, InputProps>(
  function AuthInputComponent(props, ref) {
    const [focused, setFocused] = useState(false);
    const tag = props.tag || `auth-field-${convertToSlug(props.placeholder)}`;

    return (
      <Field active={focused} highlighted={props.higlighted}>
        <Label htmlFor={tag}>{props.label}</Label>
        <Input
          {...props}
          value={props.value}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(e) => props.onChange(e.target.value)}
          ref={ref}
          id={tag}
          highlighted={props.higlighted}
        />
      </Field>
    );
  },
);

export default AuthInput;

const Field = s.div<{ active: boolean; highlighted?: boolean }>`
position: relative;
margin: 12px 0;
max-width: 340px;
width: 100%;

background: white;
border-radius: ${(props) => (props.highlighted ? "10px" : "4px")};
border: 1px solid ${(props) =>
  props.active
    ? props.highlighted
      ? "var(--color-pink)"
      : "var(--color-light-blue)"
    : "#d8d8d8"};

&:first-of-type {
  margin-top: 0;
}

&:last-of-type {
  margin-bottom: 0;
}
`;

const Label = s.label`
position: absolute;
left: calc(1rem - 3px);
top: -10px;
padding: 0 3px;

background: #fff;
font-size: 14px;
line-height: 19px;
`;

const Input = s.input<{ highlighted?: boolean }>`
display: block;
width: 100%;
padding: 0.75rem 1rem 0.5rem;
margin: 0;
box-sizing: border-box;

border-radius: ${(props) => (props.highlighted ? "10px" : "4px")};
outline: none;
border: none;
font: inherit;
font-size: 16px;
line-height: 22px;
`;
