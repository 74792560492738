import { type FormEventHandler, useEffect, useState } from "react";
import s from "styled-components";

import { log } from "shared/client";
import { send } from "utils/api";
import { MarketingEvent, track } from "utils/telemetry";

import { ButtonAccessory, RoundedButton } from "bits/Button";
import BrandComponent from "components/brand";
import Input from "components/onboarding/input";

const LS_KEY = "email-popup-closed-v1";

export default function Popup() {
  const [visible, setVisible] = useState(false);
  const [copied, setCopied] = useState(false);
  const [couponCode, setCouponCode] = useState<string>();
  const [isProcessing, setProcessing] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const value = localStorage.getItem(LS_KEY);

    if (value) return;

    const timeout = setTimeout(() => {
      setVisible(true);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  const close: FormEventHandler = (e) => {
    e.preventDefault();

    localStorage.setItem(LS_KEY, "true");
    setVisible(false);
  };

  const submit: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      if (couponCode) {
        await navigator.clipboard.writeText(couponCode);
        return setCopied(true);
      }

      if (!email.trim().length) return;

      setProcessing(true);

      const res = await send("ms/coupon", { email });

      setProcessing(false);

      if (res.data?.success && res.data.code) {
        track(MarketingEvent.emailSubscribed);
        setCouponCode(res.data.code);
      } else {
        log.error("marketing/popup", "error", {
          res,
        });
      }
    } catch (e) {
      setProcessing(false);
      log.error("marketing/popup", "network-error", {
        error: e,
      });
    }
  };

  return (
    <Modal visible={visible}>
      <CloseButton onClick={close}>Close</CloseButton>
      <BrandComponent />
      <Title>New Customer Offer</Title>
      {couponCode ? (
        <div>
          <Description>Thank you, and welcome to Publit!</Description>
          <Description>
            You can copy and paste the code for immediate use. Here is your 10%
            off promotional code:
          </Description>
        </div>
      ) : (
        <div>
          <Description>
            Showcase your history and experiences on the web today. Sign up to
            our newsletter to get 10% off.
          </Description>
          <Description>Sign up to get the promo code.</Description>
        </div>
      )}
      {couponCode ? (
        <Code>{couponCode}</Code>
      ) : (
        <InputContainer>
          <Input
            label="Email"
            placeholder="Enter Email Address"
            value={email}
            onChange={setEmail}
            type="email"
            disabled={isProcessing}
            higlighted
          />
        </InputContainer>
      )}
      <RoundedButton
        title={copied ? "Copied!" : couponCode ? "Copy Code" : "Get Code"}
        size="large"
        onClick={submit}
        width="100%"
        disabled={isProcessing || copied}
        accessory={isProcessing ? ButtonAccessory.Spinner : null}
      />
    </Modal>
  );
}

const Modal = s.form<{ visible: boolean }>`
position: fixed;
bottom: 20px;
right: ${(props) => (props.visible ? "20px" : "calc(-100% - 40px)")};
z-index: ${(props) => props.theme.z.popup};
visibility: ${(props) => (props.visible ? "visible" : "hidden")};

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 300px;
box-sizing: border-box;
padding: 40px 20px 20px;

transition: right 0.75s ease-in-out;

text-align: center;
color: black;
border-radius: 20px;
background: white;
box-shadow: 0 2px 15px rgba(118, 171, 219, 0.3), 0 0 20px rgba(118, 171, 219, 0.05);
border: 1px solid rgba(118, 171, 219, 0.3);
`;

const CloseButton = s.button`
position: absolute;
top: 20px;
right: 20px;

${(props) => props.theme.text.build("nunito", "s", "semibold")};
color: var(--color-blue);
background: transparent;
border: none;
cursor: pointer;
`;

const Title = s.h1`
margin: 30px 0 10px;
font: inherit;
text-transform: uppercase;
${(props) => props.theme.text.build("nunito", "s", "bold")};
`;

const Description = s.p`
margin: 0;
${(props) => props.theme.text.build("nunito", "s")};

&:last-child {
  margin-top: 20px;
  margin-bottom: 10px;
}
`;

const InputContainer = s.div`
width: 100%;
margin: 20px 0;
`;

const Code = s.h2`
margin: 28px 0 29px;
color: var(--color-blue);
${(props) => props.theme.text.build("nunito", "xl", "bold")};
`;
