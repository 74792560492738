import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

// NOTE: these effects may run twice in development when React Strict Mode is enabled
// see: https://react.dev/reference/react/StrictMode#fixing-bugs-found-by-re-running-effects-in-development

type Destructor = () => void;
// biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
export type TypedEffectCallback<T = void> = (val: T) => void | Destructor;

// Make SSR'd stuff happy: this effect will not actually run while rendering on the server
// however it will prevent the annoying console logs!
const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

/**
 - Creates a ref that always has the latest callback without needing to add it as a dependency.
 - See this blog post for more info on this hook: https://www.epicreact.dev/the-latest-ref-pattern-in-react
 */
export function useLatestRef<T>(value: T) {
  const ref = useRef(value);

  useIsomorphicLayoutEffect(() => {
    ref.current = value;
  });

  // Unfortunately, the linter does not realize that this returns a Ref and so
  // it will complain about the ref not being a dependency in the effect.
  // You can safely ignore this warning or pass it as a dependency if you want.
  // It will not change identity so whether or not it's passed will not affect the effect.
  return ref;
}

export function useOnMountEffect(callback: TypedEffectCallback) {
  const callbackRef = useLatestRef(callback);

  useEffect(() => {
    return callbackRef.current();
  }, [callbackRef]);
}

export function useOnUnmountEffect(callback: Destructor) {
  const callbackRef = useLatestRef(callback);

  useEffect(() => {
    return callbackRef.current;
  }, [callbackRef]);
}

/**
  - This will fire the callback once, only once the condition is met.
  - The callback does not support a destructor since it's use would be inconsistent whether
  it should trigger on unmount or when the condition is no longer met.
  - The condition function will not be called again after the first time it returns true.
  - This is generally a sketch pattern so please try to avoid using it when you can,
  it's usually a smell that something else is wrong with what you are trying to do.
 */
export function useOnceEffect(
  condition: boolean | (() => boolean),
  callback: TypedEffectCallback,
) {
  const callbackRef = useLatestRef(callback);

  const hasRun = useRef(false);

  const eligible =
    !hasRun.current &&
    (typeof condition === "function" ? condition() : condition);

  useEffect(() => {
    if (eligible) {
      hasRun.current = true;
      return callbackRef.current();
    }
  }, [eligible, callbackRef]);

  const reset = useCallback(() => {
    hasRun.current = false;
  }, []);

  return { reset, hasRun: hasRun.current };
}

export function useOnChangeEffect<T>(
  value: T,
  callback: TypedEffectCallback<T>,
) {
  const callbackRef = useLatestRef(callback);

  useEffect(() => {
    return callbackRef.current(value);
  }, [value, callbackRef]);
}

export function useCallbackOnce<F extends (...args: any[]) => void>(
  callback: F,
): { trigger: (...args: Parameters<F>) => void; reset: () => void } {
  const callbackRef = useLatestRef(callback);
  const hasTriggered = useRef(false);

  const trigger = useCallback(
    (...args: Parameters<F>): void => {
      if (!hasTriggered.current) {
        hasTriggered.current = true;
        callbackRef.current(...args);
      }
    },
    [callbackRef],
  );

  const reset = useCallback(() => {
    hasTriggered.current = false;
  }, []);

  return { trigger, reset };
}
