import Image, { type StaticImageData } from "next/image";
import { type ReactElement, cloneElement, useState } from "react";
import s from "styled-components";

import { Chevron, Lock } from "components/icons";
import { useOnChangeEffect } from "~/modules/utils/hooks";
import Header from "./header";

interface Props {
  taglines?: string[];
  callouts: string[];
  descriptions: string[];
  cta: ReactElement;

  exampleURL: string;
  sliderImages: StaticImageData[];
}

export default function GenericHero({
  taglines = [],
  callouts,
  descriptions,
  cta,

  exampleURL,
  sliderImages,
}: Props) {
  const [index, setIndex] = useState<number>(0);

  const SLIDER_COUNT = sliderImages.length;

  const previous = () => setIndex((i) => (i === 0 ? SLIDER_COUNT - 1 : i - 1));
  const next = () => setIndex((i) => (i === SLIDER_COUNT - 1 ? 0 : i + 1));

  const jump = (i: number) => () => setIndex(i);

  useOnChangeEffect(index, () => {
    const timeout = setTimeout(() => {
      setIndex((i) => (i === SLIDER_COUNT - 1 ? 0 : i + 1));
    }, 8000);

    return () => clearTimeout(timeout);
  });

  return (
    <Hero>
      <Header isHero />
      <Content>
        <Left>
          {taglines.map((tagline, i) => (
            <Tagline key={`tagline-${i}`}>{tagline}</Tagline>
          ))}

          {callouts.map((callout, i) => (
            <Callout key={`callout-${i}`}>{callout}</Callout>
          ))}

          <div>
            {descriptions.map((description, i) => (
              <Text key={`description-${i}`}>{description}</Text>
            ))}
          </div>

          <ButtonContainer mobile={false}>{cta}</ButtonContainer>
        </Left>
        <Example>
          <BrowserContainer>
            <SliderButton onClick={previous}>
              <Chevron />
            </SliderButton>
            <SliderButton onClick={next}>
              <Chevron />
            </SliderButton>
            <Browser>
              <Stoplight>
                <Light />
                <Light />
                <Light />
              </Stoplight>
              <AddressBar>
                <Lock />
                <WebsiteAdress>{exampleURL}</WebsiteAdress>
              </AddressBar>
            </Browser>
            <Portal>
              <Slider style={{ transform: `translateX(-${100 * index}%)` }}>
                {sliderImages.map((image, i) => (
                  <SlideItem key={`slide-${i}`}>
                    <Image
                      alt=""
                      src={image}
                      priority
                      fill
                      placeholder="blur"
                      sizes="(max-width: 390px) calc(100vw - 30px), (max-width: 550px) 350px, 480px"
                    />
                  </SlideItem>
                ))}
              </Slider>
            </Portal>
          </BrowserContainer>
          {sliderImages.length > 1 && (
            <SliderNavigation>
              {sliderImages.map((_, i) => (
                <SliderStop
                  key={`slider-stop-${i}`}
                  active={index === i}
                  onClick={jump(i)}
                />
              ))}
            </SliderNavigation>
          )}
        </Example>
        <ButtonContainer mobile>
          {/* we copy the cta just for mobile purposes */}
          {cloneElement(cta, {
            width: "300px",
          })}
        </ButtonContainer>
      </Content>
    </Hero>
  );
}

const Hero = s.section`
position: relative;
background: linear-gradient(104.24deg, rgba(0, 194, 255, 0) 46.96%, rgba(0, 185, 255, 0.08) 56.63%, rgba(0, 146, 255, 0.8) 72.65%, rgba(10, 97, 176, 0.9) 83.42%, #133364 100%), #FFFFFF;
`;

const Content = s.div`
display: flex;
justify-content: space-between;
align-items: center;
max-width: 1080px;
margin: 0 auto;
padding: 32px 30px;

@media (max-width: 1050px) {
  padding: 0 30px 60px;
  flex-direction: column;
}
`;

const Left = s.div`
max-width: 500px;
`;

const Tagline = s.h2`
margin: 0;
margin-bottom: 10px;

color: #FF007D;

${(props) => props.theme.text.build("manrope", "xl", "bold")};
`;

const Callout = s.h1`
margin: 0;

color: var(--color-blue);
${(props) => props.theme.text.build("crimson", "xxxl", "normal")};

@media (max-width: 1050px) {
  text-align: center;

  &:not(:first-child) {
    margin-bottom: 20px;
  }
}

@media (max-width: 550px) {
  font-size: 26px;
}
`;

const Text = s.p`
margin: 20px 0;

color: black;
${(props) => props.theme.text.build("nunito", "xl", "light")};

@media (max-width: 1050px) {
  display: none;
}
`;

const ButtonContainer = s.div<{ mobile: boolean }>`
display: ${(props) => (props.mobile ? "none" : "block")};
margin-top: 40px;

@media (max-width: 1050px) {
  display: ${(props) => (props.mobile ? "block" : "none")};
  margin-top: 20px;
}

@media (max-width: 550px) {
  margin-top: 40px;
}
`;

const Example = s.div`
display: flex;
flex-direction: column;
justify-content: center;
`;

const BrowserContainer = s.div`
position: relative;
padding: 20px;

background: rgb(255 255 255 / 40%);
border-radius: 16px;

@media (max-width: 390px) {
  padding: 15px;
}
`;

const SliderButton = s.button`
position: absolute;
top: 50%;
right: 40px;
transform: translateY(-50%);
z-index: 1;

display: flex;
justify-content: center;
align-items: center;
visibility: hidden;
opacity: 0;
width: 50px;
height: 50px;
transition: opacity 0.35s ease-in-out;

border: none;
background: ${(props) =>
  props.disabled ? "rgb(0 0 0 / 20%)" : "rgb(0 0 0 / 50%)"};
border-radius: 100%;
cursor: pointer;

svg {
  display: block;
}

&:first-child {
  left: 40px;
  right: auto;

  svg {
    transform: rotate(180deg) translateX(3px);
  }
}

${BrowserContainer}:hover & {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 550px) {
  bottom: 30px;
  right: 30px;
  top: auto;
  transform: scale(0.8);
  visibility: visible;
  opacity: 1;

  &:first-child {
    left: 30px;
  }
}
`;

const Browser = s.div`
display: flex;
align-items: center;
padding: 8px 100px 8px 18px;

background: white;
border-radius: 8px 8px 0 0;
box-shadow: 0 0 0 0.5px #e0e0e0;

@media (max-width: 550px) {
  padding: 8px 18px;
}
`;

const Stoplight = s.div`
display: grid;
grid-template-columns: 10px 10px 10px;
grid-template-rows: 10px;
grid-gap: 10px;
margin-right: 32px;
`;

const Light = s.span`
background: #000;
border-radius: 100%;

&:nth-child(1) {
  background: #F92222;
}

&:nth-child(2) {
  background: #F7FC0A;
}

&:nth-child(3) {
  background: #10E432;
}
`;

const AddressBar = s.div`
display: flex;
align-items: center;
padding: 6px 14px;
width: 100%;

background: #f1f1f1;
border-radius: 99999px;
box-shadow: inset 0px 1px 2px rgb(0 0 0 / 10%);
`;

const WebsiteAdress = s.p`
margin: 0;
margin-left: 8px;

font-size: 12px;
line-height: normal;
`;

const Portal = s.div`
position: relative;
width: 480px;
height: 500px;
overflow: hidden;

border-radius: 0 0 8px 8px;
box-shadow: 0 0 0 0.5px #e0e0e0;

@media (max-width: 550px) {
  width: 350px;
  height: 365px;
}

@media (max-width: 390px) {
  width: calc(100vw - 30px);
  height: auto;
}
`;

const Slider = s.div`
display: flex;
width: 100%;
height: 100%;

transition: transform 0.25s ease-in-out;
`;

const SlideItem = s.div`
position: relative;
flex-shrink: 0;
width: 480px;
height: 500px;

@media (max-width: 550px) {
  width: 350px;
  height: 365px;
}

@media (max-width: 390px) {
  width: calc(100vw - 30px);
  height: calc(100vw * 350 / 365);
  margin-bottom: -3px;
}
`;

const SliderNavigation = s.div`
display: grid;
justify-content: center;
grid-template-columns: repeat(4, 40px);
grid-template-rows: 6px;
grid-gap: 10px;

padding: 32px 0;

@media (max-width: 550px) {
  display: none;
}
`;

const SliderStop = s.button<{ active: boolean }>`
display: block;
margin: 0;

background: ${(props) => (props.active ? "white" : "rgb(255 255 255 / 30%)")};
border: none;
border-radius: 3px;
cursor: pointer;
`;
